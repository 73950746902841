// globalStyles.js
import { createGlobalStyle } from 'styled-components';

// Type definition for the generateSpacingClasses function
type GenerateSpacingClasses = (
  property: 'margin' | 'padding',
  values: number[]
) => string;

// Function to generate classes for margins and padding
const generateSpacingClasses: GenerateSpacingClasses = (property, values) => {
  return values
    .map(
      (value) => `
    .${property[0]}-${value} { ${property}: ${value / 4}rem; }
    .${property[0]}t-${value} { ${property}-top: ${value / 4}rem; }
    .${property[0]}r-${value} { ${property}-right: ${value / 4}rem; }
    .${property[0]}b-${value} { ${property}-bottom: ${value / 4}rem; }
    .${property[0]}l-${value} { ${property}-left: ${value / 4}rem; }
    .${property[0]}x-${value} { ${property}-left: ${value / 4}rem; ${property}-right: ${value / 4}rem; }
    .${property[0]}y-${value} { ${property}-top: ${value / 4}rem; ${property}-bottom: ${value / 4}rem; }
  `
    )
    .join('');
};

// Definition for spacing values
const spacingValues: number[] = [1, 2, 3, 4, 5];

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Geomanist";
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Bold-Webfont/geomanist-bold-webfont.eot");
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Bold-Webfont/geomanist-bold-webfont.eot#iefix") format("embedded-opentype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Bold-Webfont/geomanist-bold-webfont.woff") format("woff"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Bold-Webfont/geomanist-bold-webfont.ttf") format("truetype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Bold-Webfont/geomanist-bold-webfont.svg#Geomanist") format("svg");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "Geomanist";
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Medium-Webfont/geomanist-medium-webfont.eot");
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Medium-Webfont/geomanist-medium-webfont.eot#iefix") format("embedded-opentype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Medium-Webfont/geomanist-medium-webfont.woff") format("woff"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Medium-Webfont/geomanist-medium-webfont.ttf") format("truetype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Medium-Webfont/geomanist-medium-webfont.svg#Geomanist") format("svg");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Geomanist";
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Regular-Webfont/geomanist-regular-webfont.eot");
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Regular-Webfont/geomanist-regular-webfont.eot#iefix") format("embedded-opentype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Regular-Webfont/geomanist-regular-webfont.woff") format("woff"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Regular-Webfont/geomanist-regular-webfont.ttf") format("truetype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Regular-Webfont/geomanist-regular-webfont.svg#Geomanist") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "Geomanist";
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Light-Webfont/geomanist-light-webfont.eot");
    src: url("/fonts/Geomanist-Roman-Webfont/Geomanist-Light-Webfont/geomanist-light-webfont.eot#iefix") format("embedded-opentype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Light-Webfont/geomanist-light-webfont.woff") format("woff"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Light-Webfont/geomanist-light-webfont.ttf") format("truetype"),
    url("/fonts/Geomanist-Roman-Webfont/Geomanist-Light-Webfont/geomanist-light-webfont.svg#Geomanist") format("svg");
    font-weight: 300;
    font-style: normal;
  }

  html, body {
    overflow-x: hidden;
    margin: 0;
    width: 100% !important;
  }

  // Margins and paddings
  ${generateSpacingClasses('margin', spacingValues)}
  ${generateSpacingClasses('padding', spacingValues)}
  .mt8 {
    margin-top: 8px;
  }

  .mb8 {
    margin-bottom: 8px;
  }

  .mt16 {
    margin-top: 16px;
  }

   .mt24 {
    margin-top: 24px;
  }

  .mr16 {
    margin-right: 16px;
  }

  .mr8 {
    margin-right: 8px;
  }

  .ml8 {
    margin-left: 8px;
  }

  .ant-menu.ant-menu-sub.ant-menu-vertical {
    li[role="menuitem"].ant-menu-item-selected {
      background: ${extractColor(colorType.primary, 1)};
    }

    li[role="menuitem"].ant-menu-item-active {
      background: ${extractColor(colorType.primary, 1)};
    }
  }

  .checkbox {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        border-color: ${extractColor(colorType.blue)};
        background-color: ${extractColor(colorType.blue)};
      }

      .ant-checkbox-inner:hover {
        border-color: ${extractColor(colorType.blue)};
        background-color: ${extractColor(colorType.blue)};
      }
    }
  }

  .ml16 {
    margin-left: 16px;
  }

  .ml24 {
    margin-left: 24px;
  }

  .mr24 {
    margin-right: 24px;
  }

  .mb16 {
    margin-bottom: 16px;
  }

  .mb28 {
    margin-bottom: 28px;
  }

  .bolder {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .full-width {
    width: 100%;
  }

  .flex-cl-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .flex-between {
    display: flex;
    justify-content: space-between !important;
  }

  .flex-end {
    display: flex !important;
    justify-content: flex-end !important;
  }

  .end {
    justify-content: flex-end;
  }

  .ant-checkbox + span {
    color: ${extractColor(colorType.primary, 3)};
  }

  .flex-rw-center {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .flex-noWrap {
    flex-wrap: nowrap !important;
  }

  .flex-cl, .flex-cl-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .flex-cl, .flex-cl-right {
    display: flex;
    flex-direction: column;
    justify-content: right;
  }

  .items-center {
    align-items: center !important;
  }

  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0 !important;
  }

  .pointer {
    cursor: pointer;
    z-index: 4;
  }

  .col-direction {
    display: flex;
    flex-direction: column;
  }

  .full-height {
    height: 100%;
  }

  .ant-switch {
    transform: scale(1.2);
  }

  .notification-wrapper {
    width: 250px;
  }

  .notification-header {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${extractColor(colorType.background, 2)};
  }

  .notification-body {
    text-align: center;
    padding-bottom: 25px;
  }

  .notification-body svg {
    width: 50px;
    height: 50px;
    fill: ${extractColor(colorType.background, 3)};
    margin-bottom: 15px;
  }

  .ant-popover-inner {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25) !important;
    border-radius: 4px !important;
  }

  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${extractColor(colorType.blue, 3)} !important;
  }

  .ant-tabs-ink-bar {
    background-color: ${extractColor(colorType.blue, 3)} !important;
  }

  .layout-stretched {
    width: 80% !important;
    left: 10% !important;
  }

  .layout-stretched .top-bar-content {
    width: 80%;
    left: 10%;
  }

  .layout-windowed {
    transform: scale(0.95);
  }

  .ant-card-actions {
    background: transparent !important;
  }

  #nprogress .spinner {
    display: none !important;
  }

  #nprogress .bar {
    height: 5px;
  }

  .language-select .ant-input-group-addon {
    border-radius: 5px !important;
    background-color: ${extractColor(colorType.background, 2)} !important;
    border: 1px solid ${extractColor(colorType.background, 2)} !important;
  }

  .language-select .ant-select-selector {
    display: flex;
    min-height: 44px;
    align-items: center;
    box-shadow: none !important;
    border-radius: 5px !important;
    border: 1px solid ${extractColor(colorType.background, 2)} !important;
  }

  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-popover-inner-content {
    padding: 0 !important;
  }

  .ant-picker-footer-extra {
    display: flex;
    margin: 6px !important;
    justify-content: flex-end;
  }

  .ant-menu-item-modify {
    user-select: none;
  }

  .ant-menu-item-modify:hover {
    background-color: ${extractColor(colorType.background, 2)};
    color: inherit !important;
  }

  .ant-menu-item-icon {
    vertical-align: middle!important;
  }

  .modalStyle .ant-modal-content {
    border-radius: 10px;
    max-height: 100vh;
    overflow-y: auto;

    .ant-modal-confirm-body-wrapper .ant-modal-confirm-body {
      display: flex;
      justify-content: center;
    }
  }


  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left;
  }

  .flex-auto {
    flex: 1
  }

  .float-right {
    float: right;
  }

  .gmnoprint > div {
    background-color: transparent !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  .gm-control-active {
    height: 32px !important;
    width: 32px !important;
    background: rgba(255, 255, 255, 0.6) !important;
    border-radius: 50% !important;
    box-shadow: none !important;
    margin-bottom: 8px !important;
  }

  .simple-popover .ant-popover-inner-content {
    padding: 0;
  }

  .gm-control-active img {
    width: 12px !important;
    height: 12px !important;
  }

  .gm-control-active + div {
    background-color: transparent !important;
  }

  .gm-fullscreen-control {
    position: absolute !important;
    bottom: 105px !important;
    top: auto !important;
    right: 8px !important;
    height: 32px !important;
    width: 32px !important;
    background: rgba(255, 255, 255, 0.6) !important;
  }

  .gm-svpc {
    top: -40px !important;
    margin: 18px !important;
    margin-bottom: 16px !important;
  }

  .gm-style-mtc {
    display: none !important;
  }

  .infoBox img {
    display: none;
  }

  .ant-popover-buttons {
    padding: 4px !important;
  }

  .ant-popover-message > .anticon {
    padding: 4px !important;
  }

  .ant-table-pagination {
    background: none !important;
  }

  .ant-modal-content {
    border-radius: 12px !important;
  }

  .allow-events {
    pointer-events: auto !important;
  }

  .trigger-modal-ok-hidden {
    display: none !important;
  }

  .ant-button-emp {
    color: ${extractColor(colorType.light)} !important;
    background: ${extractColor(colorType.blue)} !important;
  }

  .primary {
    color: #003B5C !important;
  }

  .primary-valsabbina {
    color: ${extractColor(colorType.blue)} !important;
  }

  .ant-button-emp-valsabbina {
    color: ${extractColor(colorType.light)} !important;
    background: ${extractColor(colorType.blue)} !important;
  }

  .ant-button-emp {
    color: ${extractColor(colorType.light)} !important;
    background-color: #003B5C !important;
  }

  .ant-button-emp-reverse {
    color: #003B5C !important;
    background-color: ${extractColor(colorType.light)} !important;
  }

  .bg-primary {
    background-color: #003B5C !important;
  }

  .btn-primary {
    background-color: #003B5C !important;
    color: #ffff !important;
  }

  .ant-button-emp:disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    background: rgba(0, 0, 0, 0.04) !important;
  }


  .ant-picker-content .ant-picker-time-panel-column {
    width: 70px;
  }

  .ant-dropdown-menu-item-selected:hover {
    background: rgba(0, 0, 0, 0.04) !important;
  }

  .ant-select-item.ant-select-item-option.ant-select-item-option-active.ant-select-item-option-selected {
    cursor: not-allowed !important;
  }

  .ant-popover {
    z-index: 101 !important;
  }

  .ck-content {
    line-height: 1.6;
    word-break: break-word;

    .table {
      margin: 0 auto;

      table {
        max-width: 560px;
      }
    }

    figure {
      width: 100%;
    }
  }

  .ck-content {
    min-height: 100px;
  }

  hr {
    border-color: ${extractColor(colorType.primary, 1)};
  }

  // debugger helper
  code, pre {
    display: block;
    margin: 15px 0;
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word;
    white-space: pre-line;
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
  }
`;

import { colorType, extractColor } from '../../helpers';

export default GlobalStyle;
