import React from 'react';
import StyledModal from '../../../../modals/styled_modal';
import { useTranslator } from '@opyn/utils';
import { DownOutlined } from '@ant-design/icons';
import { colorType, extractColor } from '../../../../../helpers';
import { Button, Menu, MenuProps, Popover, Spin } from 'antd';
import styled from 'styled-components';
import { TActions } from '../../index';
import { ItemType } from 'antd/es/menu/interface';

const DownOutlinedIcon = styled(DownOutlined)`
  font-size: 12px;
  color: ${extractColor(colorType.primary, 1)};
`;

const CustomButton = styled(Button)`
  min-width: 140px;
`;

interface Props {
  item: Record<string, any>;
  disabled?: boolean;

  [x: string]: any;
}

const ActionView = (props: Props) => {
  const { item, disabled, ...rest } = props;
  const [visible, setVisible] = React.useState(false);

  const openModal = () => {
    setVisible(true);
  };

  const closeModal = () => {
    setVisible(false);
  };

  const translator = useTranslator();

  const Component = item.component;
  const ModalContent = item.modal;

  const translateId = item.getTranslateId
    ? item.getTranslateId(props)
    : item.translateId;

  const style = item.getStyle ? item.getStyle(props) : item.style;

  const label = translator.translate(translateId);

  const content = item.render ? (
    item.render(rest)
  ) : (
    <span style={style}>{label}</span>
  );

  if (Component) return <Component {...rest} />;

  const modalProps = item.modalProps || {};

  if (ModalContent) {
    const { key, ...restItem } = item;
    return (
      <>
        <div
          className="full-width full-height"
          onClick={disabled ? undefined : openModal}
        >
          {content}
        </div>
        <StyledModal
          centered
          {...restItem}
          footer={null}
          {...modalProps}
          open={visible}
          onCancel={closeModal}
          destroyOnClose={true}
          style={item.modalStyle}
          title={
            item.modalTitleTranslateId
              ? translator.translate(item.modalTitleTranslateId)
              : null
          }
        >
          <ModalContent onClose={closeModal} {...item} {...rest} />
        </StyledModal>
      </>
    );
  }
  return content;
};
const ButtonView = (props: Props) => {
  const { item, type, key, ...rest } = props;
  const params: { disabled?: boolean } = {};

  if (Object.prototype.hasOwnProperty.call(item, 'disabled')) {
    params.disabled =
      typeof item.disabled === 'function' ? item.disabled(rest) : item.disabled;
  }

  const disabled =
    Object.prototype.hasOwnProperty.call(item, 'disabled') &&
    typeof item.disabled === 'function'
      ? item.disabled(rest)
      : item.disabled;

  let title = (
    <ActionView disabled={disabled} item={item} key={key} {...rest} />
  );
  title = typeof item.title === 'function' ? item.title({ ...props }) : title;
  delete item.key;
  return (
    <Button
      {...item.buttonProps}
      {...params}
      onClick={() => !item.modal && item.onClick({ ...props })}
    >
      {title}
    </Button>
  );
};

interface ItemMenuProps {
  key: number;
  item: Record<string, any>;
  record: object;
}

const getItemMenu = (props: ItemMenuProps): ItemType => {
  const { item, key, ...rest } = props;

  const disabled =
    Object.prototype.hasOwnProperty.call(item, 'disabled') &&
    typeof item.disabled === 'function'
      ? item.disabled(rest)
      : item.disabled;

  const label = (
    <ActionView disabled={disabled} item={item} key={key} {...rest} />
  );

  if (item.children) {
    const children: MenuProps['items'] = item.children?.map(
      (child: Record<string, any>, index: number) => {
        return getItemMenu({ key: index, item: child, record: props.record });
      }
    );
    return {
      label,
      key: item.key,
      style: item.style,
      disabled,
      className: 'ant-sidebar-menu-item-modify',
      children,
    };
  }

  if (item.isLoading) {
    return {
      label: <Spin spinning={item.isLoading}>{label}</Spin>,
      key: item.key,
      disabled,
      onClick: () => {
        if (!item.onClick || disabled) return;
        item.onClick({ ...props });
      },
      className: 'ant-sidebar-menu-item-modify',
    };
  }

  return {
    label,
    key: props.key,
    disabled,
    onClick: () => {
      if (!item.onClick || disabled) return;
      item.onClick({ ...props });
    },
    className: 'ant-sidebar-menu-item-modify',
  };
};

interface ActionsProps {
  list: TActions[] | undefined;
  type?: string;
  index?: number;
  width?: number;
  record: object;
  showGroup?: boolean;
  showDisableActions?: boolean;
  isMoreActionsDisabled?: boolean;
  showDropdown?: boolean;
}

const Actions = ({
  list,
  width = 260,
  record,
  showGroup = true,
  showDisableActions,
  isMoreActionsDisabled,
  showDropdown,
}: ActionsProps) => {
  const translator = useTranslator();
  const [visible, setVisible] = React.useState(false);
  const toggleVisibility = () => {
    setVisible((v) => !v);
  };
  const onAfterClick = () => {
    setTimeout(() => setVisible(false), 50);
  };

  if (!list || !list.length) return null;

  if (list.length === 1 && !showDropdown) {
    return <ButtonView item={list[0]} record={record} />;
  }

  if (list.length && showDisableActions === false) {
    const filterRecords = list.filter(
      (l: { disabled?: boolean }) => !l.disabled
    );
    if (filterRecords.length === 1) {
      return <ButtonView item={filterRecords[0]} record={record} />;
    }
  }

  const items: MenuProps['items'] = list?.map((item, index: number) => {
    return getItemMenu({ key: index, item, record });
  });

  if (showGroup) {
    items.unshift({
      type: 'group',
      label: translator.translate('moreActions.label'),
    });
  }

  return (
    <Popover
      title={null}
      trigger="click"
      open={visible}
      onOpenChange={toggleVisibility}
      content={
        <Menu
          style={{ width }}
          mode="vertical"
          selectedKeys={[]}
          onClick={onAfterClick}
          items={items}
        />
      }
      placement="bottomRight"
    >
      <CustomButton
        data-cy="moreActionsButton"
        disabled={isMoreActionsDisabled}
      >
        {translator.translate('moreActions.label')} <DownOutlinedIcon />
      </CustomButton>
    </Popover>
  );
};

export default Actions;
